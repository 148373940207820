import { ModalContext } from 'context/3catinfo/ModalProvider';
import { useContext } from 'react';

export const useModal = () => {
    const {
        openModal,
        modalContent,
        mutePlayer,
        setModal,
        removeModal,
        setMutePlayer,
        centerDivOnModal,
        topDivOnModal,
        optionsModal
    } = useContext(ModalContext);

    return {
        openModal,
        modalContent,
        mutePlayer,
        setModal,
        removeModal,
        setMutePlayer,
        centerDivOnModal,
        topDivOnModal,
        optionsModal
    };
};
