import { getStorageValue, setStorageValue } from '@portal-internet/core';
import { getData } from '@portal-internet/bff';
import { useUser } from 'hooks/useUser';
import __get from 'lodash/get';
import __isEmpty from 'lodash/isEmpty';
import { createContext, useEffect, useState } from 'react';
const firmes = require('./firmes.json');
const temes = require('./temes.json');
const noticies = require('./noticies.json');
const tot = require('./tot.json');

const expiresInMinutes = 5;
const minutesPerDay = 1440;
const expires = expiresInMinutes / minutesPerDay;

const urlBase = 'https://api.3cat.cat';
export const ContentUserContext = createContext();

const ContentUserProvider = (props) => {
    const { children } = props;
    const { isAuthenticated, accessToken, uuid } = useUser();

    const [itemsLaMevaLlista, setItemsLaMevaLlista] = useState({});
    const [lastFetchedTimestamp, setLastFetchedTimestamp] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            obtenirItemsLaMevaLlista();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, accessToken]);

    const isStorageAvailable = () => {
        return !!(typeof window !== 'undefined' && window?.CCMAStorage);
    };
    const shouldProcess = () => {
        return isAuthenticated && isStorageAvailable();
    };
    const shouldFetch = () => {
        //toca mirar ccmastorage o api?
        const itemsNotLoaded = __isEmpty(itemsLaMevaLlista);
        const itemsExpired = Date.now() - lastFetchedTimestamp > expiresInMinutes * 60000;
        return itemsExpired || itemsNotLoaded;
    };

    const getItemsFromCCMAStorage = async () => {
        return await getStorageValue(['_ccma_lamevallista_3catinfo_', uuid].join(''));
    };

    const obtenirItemsLaMevaLlista = async () => {
        if (shouldProcess() && shouldFetch()) {
            let items = {};
            const plainData = await getItemsFromCCMAStorage();
            try {
                items = JSON.parse(plainData);
            } catch (e) {
                items = {};
            }
            if (__isEmpty(items)) {
                items = await getItemsFromApi();
            }

            setItemsLaMevaLlista(items);
            setLoaded(true);
            setLastFetchedTimestamp(Date.now());
        }
    };
    const isStatusOk = (rawData) => {
        return __get(rawData, 'resposta.status') === 'OK';
    };

    const removeItemToAPI = async (id, tipologia) => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/usuaris/llistes/esborrarcontingut?tipus_llista=permestard&tipologia=${tipologia}&contingut_id=${id}&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista-remove', { url, fetchFromOrigin: true }] });
        return isStatusOk(rawData);
    };
    const addItemToAPI = async (id, tipologia) => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/usuaris/llistes/afegircontingut?tipus_llista=permestard&tipologia=${tipologia}&contingut_id=${id}&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista-add', { url, fetchFromOrigin: true }] });
        return isStatusOk(rawData);
    };

    const getItemsFromApi = async () => {
        if (!isAuthenticated || !accessToken) return false;
        const url = `${urlBase}/usuaris/llistes/obtenircontinguts?_format=json&tipus_llista=permestard&tipologia=NOT_NOTICIA,NOT_AUTOR&cache=30&vista=HISTORIC&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista', { url, fetchFromOrigin: true }] });
        let items = [];

        if (isStatusOk(rawData)) {
            items = rawData.resposta?.items?.item?.reduce((list, item) => {
                if (!list[item.tipologia]) {
                    list[item.tipologia] = [];
                }
                list[item.tipologia].push(Number(item.contingut_id));
                
                return list;
            }, {});
            setItemsToCCMAStorage(items);
        }
        return items;
    };

    const getFullItemsFromApi = async (tipologia, pagina, numItems) => {
        if (!isAuthenticated || !accessToken || __isEmpty(itemsLaMevaLlista)) return false;
        setIsLoading(true);

        const idsParam = itemsLaMevaLlista !== undefined && itemsLaMevaLlista[tipologia] !== undefined
            ? '&id=' + Object.values(itemsLaMevaLlista[tipologia]).join(',')
            : '';

        const urlNot = `${urlBase}/noticies?_format=json${idsParam}&origen=llistat&items_pagina=${numItems}&pagina=${pagina}&sdom=img&debug=no&version=2.0&cache=90&redl=false&master=yes&perfils_extra=imatges_3catinfo`;
        const notRawData = await getData({ queryKey: ['itemsNoticies', { url: urlNot, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(notRawData, 'resposta.status') === 'OK';
        const items = isStatusNotisOk ? __get(notRawData, 'resposta') : [];
        const blocsInfoContingut = Array.isArray(items?.items?.item)
            ? items?.items.item
            : [];

        const paginacio = isStatusNotisOk ? __get(notRawData, 'resposta.paginacio') : [];
        const moreData ={paginacio, url: urlNot}

        setIsLoading(false);
        return { blocsInfoContingut, moreData };
    };

    const getFullItemsFromApiDtyCategory = async (tipologia, pagina, numItems) => {
        if (!isAuthenticated || !accessToken || __isEmpty(itemsLaMevaLlista)) return false;
        setIsLoading(true);

        const url = `${urlBase}/usuaris/llistes/obtenircontinguts?_format=json&tipus_llista=permestard&tipologia=${tipologia}&cache=30&version=2.0&https=true&master=yes&sdom=img&pagina=${pagina}&items_pagina=${numItems}&usuari_id=${accessToken}`;
        const rawData = await getData({ queryKey: ['itemsLaMevaLlista', { url, fetchFromOrigin: true }] });

        const isStatusOk = __get(rawData, 'resposta.status') === 'OK';
        const items = isStatusOk ? __get(rawData, 'resposta') : [];
        const blocsIdsTematiques = Array.isArray(items?.items?.item)
            ? items?.items.item.map((element) => element.id)
            : [];

        if(isStatusOk){
            const urlNot = `${urlBase}/noticies?_format=json&tag=${blocsIdsTematiques[0]}&origen=llistat&items_pagina=14&pagina=1&sdom=img&debug=no&version=2.0&cache=90&redl=false&master=yes&perfils_extra=imatges_3catinfo`;
            const notRawData = await getData({ queryKey: ['itemsNoticies', { url: urlNot, fetchFromOrigin: true }] });

            const isStatusNotisOk = __get(notRawData, 'resposta.status') === 'OK';
            const items = isStatusNotisOk ? __get(notRawData, 'resposta') : [];
            const blocsInfoContingut = Array.isArray(items?.items?.item)
                ? items?.items.item
                : [];

            const autorsFiltrats = blocsInfoContingut
            .flatMap((item) => item.autors) // Extrae y aplana los arrays de autors
            .filter((autor, index, self) => 
                blocsIdsAutors.includes(Number(autor.id)) && 
                index === self.findIndex(a => a.id === autor.id) // Elimina duplicados por ID
            );

            console.log('autorsFiltrats', autorsFiltrats);
            const paginacio = isStatusNotisOk ? __get(notRawData, 'resposta.paginacio') : [];
            const moreData ={paginacio, url: urlNot}

            setIsLoading(false);
            return { blocsInfoContingut, autorsFiltrats, moreData };
        }

        setIsLoading(false);
        return {};
    };

    const getFullAutorsInfo = async () => {
        if (!isAuthenticated || !accessToken || itemsLaMevaLlista['NOT_AUTOR'].length === 0) return false;

        setIsLoading(true);

        let idsParamsAutors = '&id=' + Object.values(itemsLaMevaLlista['NOT_AUTOR']).join(',');    

        const urlAutors = `${urlBase}/autor?_format=json${idsParamsAutors}&sdom=img&origen=llistat&debug=no&version=2.0&pagina=1&cache=90&redl=false&master=no`;
        const autorsRawData = await getData({ queryKey: ['itemsAutors', { url: urlAutors, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(autorsRawData, 'resposta.status') === 'OK';
        const items = isStatusNotisOk ? __get(autorsRawData, 'resposta') : [];
        const blocsInfoAutors = Array.isArray(items?.items?.item)
            ? items?.items.item
            : [];
        setIsLoading(false);
        return { blocsInfoAutors };
    };

    const getFullItemsFromApiNotAutor = async (tipologia, pagina, numItems, selectedFirmaId, noIds) => {
        if (!isAuthenticated || !accessToken || __isEmpty(itemsLaMevaLlista)) return false;
        setIsLoading(true);
        const noIdsParam = noIds 
            ? '&no_id=' + Object.values(noIds).join(',')
            : '';

        let idsParamsAutors = itemsLaMevaLlista !== undefined && itemsLaMevaLlista[tipologia] !== undefined
            ? '&autor_id=' + Object.values(itemsLaMevaLlista[tipologia]).join(',')
            : '';    

        if(selectedFirmaId && selectedFirmaId !== 'TOTES'){
            idsParamsAutors = '&autor_id=' + selectedFirmaId;
        }

        const urlNot = `${urlBase}/noticies?_format=json${idsParamsAutors}&origen=llistat&items_pagina=${numItems}&pagina=${pagina}&sdom=img&debug=no&version=2.0&cache=90&redl=false&master=yes&perfils_extra=imatges_3catinfo${noIdsParam}`;
        const notRawData = await getData({ queryKey: ['itemsNoticies', { url: urlNot, fetchFromOrigin: true }] });

        const isStatusNotisOk = __get(notRawData, 'resposta.status') === 'OK';
        const items = isStatusNotisOk ? __get(notRawData, 'resposta') : [];
        const blocsInfoContingut = Array.isArray(items?.items?.item)
            ? items?.items.item
            : [];

        const paginacio = isStatusNotisOk ? __get(notRawData, 'resposta.paginacio') : [];
        const moreData ={paginacio, url: urlNot}

        setIsLoading(false);
        return { blocsInfoContingut, moreData };
    };

    const setItemsToCCMAStorage = (items) => {
        const plainData = JSON.stringify(items);
        setStorageValue(['_ccma_lamevallista_3catinfo_', uuid].join(''), plainData, {
            path: '/',
            domain: '.3cat.cat',
            expires: expires
        });
    };

    const isALaMevaLlista = (idint, tipologia) => {
        const itemId = typeof idint === 'number' ? Number(idint) : idint;
        return itemsLaMevaLlista?.[tipologia]?.includes(itemId);
    };

    const eliminaALaMevaLlista = async (idint, tipologia) => {
        let isStatusOk = false;
        setIsLoading(true);
        const items = itemsLaMevaLlista;
        if (items[tipologia]) {
            const itemId = typeof idint === 'number' ? Number(idint) : idint;
            const index = items[tipologia].indexOf(itemId);
            if (index > -1) {
                items[tipologia].splice(index, 1);
                updateItemsLaMevaLlista(items);
                isStatusOk = removeItemToAPI(idint, tipologia);
            }
        }
        setIsLoading(false);
        return isStatusOk;
    };

    const afegeixALaMevaLlista = async (idint, tipologia) => {
        let isStatusOk = false;
        setIsLoading(true);
        let items = itemsLaMevaLlista;
        if (!items[tipologia]) {
            items[tipologia] = [];
        }
        if (!isALaMevaLlista(idint, tipologia)) {
            const itemId = typeof idint === 'number' ? Number(idint) : idint;
            items[tipologia].push(itemId);
            updateItemsLaMevaLlista(items);
            isStatusOk = addItemToAPI(idint, tipologia);

        }
        setIsLoading(false);
        return isStatusOk;
    };
    const updateItemsLaMevaLlista = (items) => {
        setItemsToCCMAStorage(items);
        setItemsLaMevaLlista(items);
    };

    const toggleSelection = async (id, tipologia) => {
        if (!isALaMevaLlista(id, tipologia)) {
            await afegeixALaMevaLlista(id, tipologia);
        } else {
            await eliminaALaMevaLlista(id, tipologia);
        }
        console.log('ItemsLaMevaLlista', itemsLaMevaLlista);
    };

    return (
        <ContentUserContext.Provider
            value={{
                itemsLaMevaLlista,
                toggleSelection,
                isALaMevaLlista,
                eliminaALaMevaLlista,
                afegeixALaMevaLlista,
                getFullItemsFromApi,
                getFullItemsFromApiDtyCategory,
                getFullItemsFromApiNotAutor,
                getFullAutorsInfo,
                isLoading,
                loaded
            }}
        >
            {children}
        </ContentUserContext.Provider>
    );
};

export default ContentUserProvider;
