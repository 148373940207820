import { getData } from '@portal-internet/bff';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import crypto from 'crypto';
import __indexOf from 'lodash/indexOf';
import __pullAt from 'lodash/pullAt';
import __cloneDeep from 'lodash/cloneDeep';
import __get from 'lodash/get';
import __set from 'lodash/set';
import __mapKeys from 'lodash/mapKeys';
import RedlNotRenderableInfo from 'components/REDL/RedlNotRenderableInfo';

import LayoutChangesTmpStorageService from './layoutChangesTmpStorageService';

export const RedlMetadataContext = createContext({
    setRedlPageMetadata: (pathname, allData, structure) => {},
    getRedlPageMetadata: (pathname) => {},
    getRedlBlocMetadata: (pathname, blocIdx) => {},
    setRedlPageRawLayout: (pathname, rawLayout) => {},
    getRedlPageRawLayout: (pathname) => {},
    getRedlBlocRawLayout: (pathname, blocIdx) => {},
    moveRedlBloc: (pathname, blocIdx, newIndex) => {},
    addRedlBloc: (pathname, newBlock, newIndex, sources) => {},
    editRedlBloc: (pathname, newBlock, newIndex, sources) => {},
    deleteRedlBloc: (pathname, blocIdx) => {},
    setRedlNotRenderableInfo: (dataId, rootIndex, componentName) => {},
    setRedlRenderableInfo: (pathname, newStructure, isReset) => {},
    resetRedlNotRenderableInfo: () => {},
    setIsPageRawLayoutShouldBeStored: () => {}
});

const RedlMetadataProvider = (props) => {
    const [pageMetadata, setPageMetadata] = useState({});
    const [pageRawLayout, setPageRawLayout] = useState({});
    const [isPageRawLayoutShouldBeStored, setIsPageRawLayoutShouldBeStored] = useState(false);
    const [pageNotRenderableInfo, setPageNotRenderableInfo] = useState({});
    const [showNotRenderableInfo, setShowNotRenderableInfo] = useState(false);

    const resetRedlNotRenderableInfo = () => {
        setPageNotRenderableInfo({});
        setShowNotRenderableInfo(false);
    };

    const router = useRouter();
    useEffect(() => {
        const handleRouteChange = (url) => {
            resetRedlNotRenderableInfo();
        };

        router.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [pageRawLayout, router.events]);

    useEffect(() => {
        if (isPageRawLayoutShouldBeStored && pageRawLayout) {
            LayoutChangesTmpStorageService.save(pageRawLayout);
        }
    }, [pageRawLayout, isPageRawLayoutShouldBeStored]);

    const publicApi = useMemo(() => {
        const setRedlPageMetadata = (pathname, allData = [], structure, components, layoutURLHost) => {
            setPageMetadata((prevState) => {
                if (!prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: {
                            allData: [...allData],
                            structure: [...structure],
                            components: [...components],
                            layoutURLHost,
                            editId: 0,
                            isReset: true,
                        }
                    };
                }
                return prevState;
            });
        };

        const setRedlPageRawLayout = (pathname, rawLayout) => {
            setPageRawLayout((prevState) => {
                if (!prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: rawLayout
                    };
                }
                return prevState;
            });
        };

        const getRedlPageMetadata = (pathname) => {
            return pageMetadata[pathname];
        };

        const getRedlPageRawLayout = (pathname) => {
            return pageRawLayout[pathname];
        };

        const _getRedlBlocCurrentIdx = (pathname, blocIdx) => {
            const structure = pageMetadata[pathname]?.structure;
            if (!structure) return [null, -1];
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            if (!zonaEditableChildren) return [null, -1];

            return [zonaEditableChildren, __indexOf(zonaEditableChildren, zonaEditableChildren.find((element) => element.blocIdx === blocIdx))];
        };

        const getRedlBlocMetadata = (pathname, blocIdx) => {
            const [structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            return structure[currentIdx];
        };

        const _addEditablePropsToBlocRawLayout = (structure, allComponentsEditableProps) => {
            if (!structure) return
            if (allComponentsEditableProps[structure.name]) { structure.editableProps = allComponentsEditableProps[structure.name]; }
            if (Array.isArray(structure.children)) {
                structure.children.map((component) => {
                    _addEditablePropsToBlocRawLayout(component, allComponentsEditableProps)
                });
            }
            return structure;
        };

        const getRedlBlocRawLayout = (pathname, blocIdx) => {
            const [, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            const rawLayout = pageRawLayout[pathname];
            const structure = rawLayout?.structure || [];
            const zonaEditable = structure.find((c) => c.name === 'ZonaEditable')?.children;
            if (currentIdx < 0 || !structure || !zonaEditable) return;
            const structureWithoutEditableProps = __cloneDeep(zonaEditable[currentIdx]);
            return _addEditablePropsToBlocRawLayout(structureWithoutEditableProps, pageRawLayout[pathname]?.editableProps || {});
        };

        const _renderRawLayoutOnServer = async (pathname, newStructure, newAllData, isReset) => {
            resetRedlNotRenderableInfo();
            const rawLayout = {
                ...pageRawLayout[pathname],
                structure: newStructure,
                ...(newAllData && { allData: newAllData })
            };
            const rawLayoutMD5 = crypto.createHash('md5').update(JSON.stringify(rawLayout)).digest('hex');
            let slugs = pathname.split('/');
            slugs[0] = 'web';
            const data = await getData({
                queryKey: { type: 'layout', context: { query: { editMode: true } }, options: { slugs: slugs, layout: rawLayoutMD5 } },
                layoutOnTheFly: rawLayout
            });
            let renderedByServer = data?.props;
            const structureRenderedByServer = renderedByServer?.layout?.structure;
            const dataKeysRenderedByServer = renderedByServer?.rawLayout?.dataKeys;
            setPageMetadata((prevState) => {
                if (prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            structure: structureRenderedByServer,
                            dataKeys: dataKeysRenderedByServer,
                            editId: prevState[pathname].editId + 1,
                            isReset: isReset || false,
                        }
                    };
                }
                return prevState;
            });
            setPageRawLayout((prevState) => {
                if (prevState[pathname]) {
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            dataKeys: dataKeysRenderedByServer,
                        }
                    };
                }
                return prevState;
            });
        };

        const _moveRedlBlocOnPageRawLayout = (pathname, currentIdx, newIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (!zonaEditableIndex) return;

            const removedRedlBloc = __pullAt(zonaEditableChildren, currentIdx)[0];
            const newZonaEditableChildren = newIdx < zonaEditableChildren.length
                ? [...zonaEditableChildren.slice(0, newIdx), removedRedlBloc, ...zonaEditableChildren.slice(newIdx)]
                : [...zonaEditableChildren, removedRedlBloc];
            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;

            const newAllData = _updateSources(pathname, {}, newStructure[zonaEditableIndex])

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData);
        };

        const _addRedlBlocOnPageRawLayout = (pathname, newBlock, newIdx, sources) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (!zonaEditableIndex) return;

            // add new block to structure
            const newZonaEditableChildren = [...zonaEditableChildren.slice(0, newIdx), newBlock, ...zonaEditableChildren.slice(newIdx)];
            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;

            const newAllData = _updateSources(pathname, sources, newStructure[zonaEditableIndex])

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData);
        };

        const _editRedlBlocOnPageRawLayout = (pathname, newBlock, newIdx, sources) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;
            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (!zonaEditableIndex) return;

            const newZonaEditableChildren = [...zonaEditableChildren.slice(0, newIdx), newBlock, ...zonaEditableChildren.slice(newIdx + 1)];
            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;

            const newAllData = _updateSources(pathname, sources, newStructure[zonaEditableIndex])

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData);
        };

        const _deleteRedlBlocOnPageRawLayout = (pathname, currentIdx) => {
            const structure = pageRawLayout[pathname]?.structure;
            if (!structure) return;

            const zonaEditableChildren = structure.find((c) => c.name === 'ZonaEditable')?.children;
            const zonaEditableIndex = structure.findIndex((c) => c.name === 'ZonaEditable');
            if (zonaEditableIndex === -1 || currentIdx < 0 || currentIdx >= zonaEditableChildren.length) return;

            const newZonaEditableChildren = [
                ...zonaEditableChildren.slice(0, currentIdx),
                ...zonaEditableChildren.slice(currentIdx + 1)
            ];

            const newStructure = [...structure];
            newStructure[zonaEditableIndex].children = newZonaEditableChildren;

            const newAllData = _updateSources(pathname, { }, newStructure[zonaEditableIndex])

            setPageRawLayout((prevState) => {
                return {
                    ...prevState,
                    [pathname]: {
                        ...prevState[pathname],
                        allData: newAllData,
                        structure: newStructure
                    }
                };
            });
            setIsPageRawLayoutShouldBeStored(true);

            _renderRawLayoutOnServer(pathname, newStructure, newAllData);
        };

        const moveRedlBloc = (pathname, blocIdx, newIndex) => {
            const [structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            const newIdx = newIndex < 0 ? 0 : newIndex >= structure.length ? structure.length - 1 : newIndex;
            _moveRedlBlocOnPageRawLayout(pathname, currentIdx, newIdx);
        };

        const addRedlBloc = (pathname, newBlock, newIndex, sources) => {
            _addRedlBlocOnPageRawLayout(pathname, newBlock, newIndex, sources);
        };

        const editRedlBloc = (pathname, newBlock, newIndex, sources) => {
            _editRedlBlocOnPageRawLayout(pathname, newBlock, newIndex, sources);
        };

        const deleteRedlBloc = (pathname, blocIdx) => {
            const [_structure, currentIdx] = _getRedlBlocCurrentIdx(pathname, blocIdx);
            if (currentIdx < 0) return;

            _deleteRedlBlocOnPageRawLayout(pathname, currentIdx);
        };

        const _updateSources = (pathname, sources, newZonaEditable) => {// update data sources
            const allData = pageRawLayout[pathname]?.allData;
            const dataKeys = pageRawLayout[pathname]?.dataKeys;
            // update data source
            __mapKeys(sources, (value, key) => {
                __set(allData, `[0].main[${key}]`, value);
                if (!dataKeys.includes(key)){
                    dataKeys.push(key);
                }
                __set(dataKeys, `${key}`, key);
            });
            // create new all data for used sources
            const newAllData = __cloneDeep(allData)
            __set(newAllData, `[0].main`, { });
            __mapKeys(__get(allData, `[0].main`), (value, key) => {
                if (_isSourceUsed(key, value, newZonaEditable, dataKeys)) {
                    __set(newAllData, `[0].main[${key}]`, value);
                }
            })
            return newAllData
        }

        const _isSourceUsed = (key, urlData, newZonaEditable, dataKeys) => {
            const result = dataKeys.includes(key);
            if (!result) {
                return newZonaEditable?.children?.some((item) => _isSourceUsed(key, urlData, item, dataKeys));
            }
            return result;
        };

        const setRedlNotRenderableInfo = (dataId, rootIndex, componentName) => {
            setPageNotRenderableInfo((prevState) => {
                const newState = { ...prevState };
                __set(newState, [dataId, rootIndex], componentName);
                return newState;
            });
            setShowNotRenderableInfo(true);
        };

        const setRedlRenderableInfo = (pathname, newStructure, isReset) => {
            if (newStructure) {
                setPageRawLayout((prevState) => {
                    return {
                        ...prevState,
                        [pathname]: {
                            ...prevState[pathname],
                            structure: __cloneDeep(newStructure)
                        }
                    };
                });
                _renderRawLayoutOnServer(pathname, newStructure, undefined, isReset);
            }
        };

        return {
            setRedlPageMetadata,
            getRedlPageMetadata,
            getRedlBlocMetadata,
            setRedlPageRawLayout,
            getRedlPageRawLayout,
            getRedlBlocRawLayout,
            moveRedlBloc,
            addRedlBloc,
            editRedlBloc,
            deleteRedlBloc,
            setRedlNotRenderableInfo,
            setRedlRenderableInfo,
            setIsPageRawLayoutShouldBeStored
        };
    }, [pageMetadata, pageRawLayout]);

    return (
        <RedlMetadataContext.Provider value={publicApi}>
            {showNotRenderableInfo && <RedlNotRenderableInfo pageNotRenderableInfo={pageNotRenderableInfo} />}
            {props.children}
        </RedlMetadataContext.Provider>
    );
};

export default RedlMetadataProvider;